<template>
  <main class="assign-category-v2" :key="componentKey">
    <div>
      <v-main app>
        <div
          class="page-wrapper-shopify"
          :class="{ 'is-full-screen': isShopify }"
        >
          <div>
            <div id="PolarisPortalsContainer">
              <div data-portal-id="popover-Polarisportal4"></div>
            </div>
          </div>

          <div class="assign-category">
            <div>
              <!-- Top Section -->

              <!-- Table section -->
              <div class="row" style="margin-top: 0" v-if="showProducts">
                <div class="col-sm-12">
                  <div
                    class="assign-category-table"
                    :class="{ 'Polaris-Card': isShopify }"
                  >
                    <div>
                      <div class="row" v-if="showProducts">
                        <div class="col-sm-12">
                          <div class="assign-category-header">
                            <div class="tablefilters">
                              <div
                                v-for="filter in filterOptions"
                                :key="filter.value"
                                @click="toggleFilter(filter.value)"
                                :class="{
                                  active: isSelectedFilter(filter.value),
                                }"
                                class="filter-option"
                              >
                                {{ filter.label }}
                              </div>
                            </div>

                            <div
                              class="buttons-container buttons-container-sort"
                              v-if="isShopify && showProducts"
                            >
                              <div
                                class="assign-search-container"
                                v-if="isActiveSearch"
                              >
                                <div
                                  v-if="isShopify"
                                  style="
                            display: flex;
                            border: 1px solid #ededed;
                          
                            border-radius: 5px;
                            width: 100%;
  }
                          "
                                >
                                  <input
                                    id="search"
                                    autocomplete="off"
                                    class="Polaris-TextField__Input"
                                    aria-labelledby="PolarisTextField3Label"
                                    aria-invalid="false"
                                    placeholder="Search your products"
                                    v-model.trim="searchQuery"
                                  />
                                  <div>
                                    <button
                                      @click="search"
                                      class="Polaris-Button Search-your-products-btn Polaris-Button--primary"
                                      type="button"
                                      style="margin-right: 0"
                                    >
                                      <span class="Polaris-Button__Content">
                                        <span class="Polaris-Button__Text">
                                          Search
                                        </span>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <span
                                  @click="removeSearch"
                                  class="cancel-button"
                                >
                                  Cancel
                                </span>
                              </div>
                              <div class="d-flex" v-if="isShopify">
                                <div
                                  @click="isActiveSearch = !isActiveSearch"
                                  style="
                                    transition: opacity 150ms
                                      var(--p-motion-ease);
                                    opacity: 1;
                                  "
                                >
                                  <button
                                    v-if="!isActiveSearch"
                                    class="Polaris-Button Polaris-Button-search Polaris-Button--iconOnly Polaris-Button--sizeSlim"
                                    aria-label="Search and filter results"
                                    type="button"
                                    tabindex="0"
                                    aria-describedby=":rvc:"
                                    data-polaris-tooltip-activator="true"
                                  >
                                    <span class="Polaris-Button__Content">
                                      <span class="Polaris-Button__Icon">
                                        <div
                                          class="Polaris-InlineStack d-flex"
                                          style="
                                            --pc-inline-stack-wrap: wrap;
                                            --pc-inline-stack-gap-xs: var(
                                              --p-space-0
                                            );
                                          "
                                        >
                                          <span
                                            class="Polaris-Icon Polaris-Icon--toneBase Polaris-Icon--applyColor"
                                          >
                                            <span
                                              class="Polaris-Text--root Polaris-Text--visuallyHidden"
                                            >
                                            </span>
                                            <svg
                                              viewBox="0 0 20 20"
                                              class="Polaris-Icon__Svg"
                                              focusable="false"
                                              aria-hidden="true"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                d="M12.323 13.383a5.5 5.5 0 1 1 1.06-1.06l2.897 2.897a.75.75 0 1 1-1.06 1.06l-2.897-2.897Zm.677-4.383a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
                                              ></path>
                                            </svg>
                                          </span>
                                          <span
                                            class="Polaris-Icon Polaris-Icon--toneBase Polaris-Icon--applyColor"
                                          >
                                            <span
                                              class="Polaris-Text--root Polaris-Text--visuallyHidden"
                                            >
                                            </span>
                                            <svg
                                              viewBox="0 0 20 20"
                                              class="Polaris-Icon__Svg"
                                              focusable="false"
                                              aria-hidden="true"
                                            >
                                              <path
                                                d="M3 6a.75.75 0 0 1 .75-.75h12.5a.75.75 0 0 1 0 1.5h-12.5a.75.75 0 0 1-.75-.75Z"
                                              ></path>
                                              <path
                                                d="M6.75 14a.75.75 0 0 1 .75-.75h5a.75.75 0 0 1 0 1.5h-5a.75.75 0 0 1-.75-.75Z"
                                              ></path>
                                              <path
                                                d="M5.5 9.25a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5h-9Z"
                                              ></path></svg
                                          ></span></div></span
                                    ></span>
                                  </button>
                                </div>

                                <BaseSelectDropSort
                                  class="Sort-type"
                                  :placeholder="'Sort'"
                                  :options="sortList"
                                  :selectedOption="selectedSort"
                                  @selectOption="SortBy($event)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <table class="Polaris-DataTable__Table" v-if="isShopify">
                      <thead>
                        <tr class="tr-header" v-if="selected.length == 0">
                          <th
                            data-polaris-header-cell="true"
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn Polaris-DataTable__Cell--header"
                            scope="col"
                          >
                            <label class="custom-checkbox-2">
                              <input
                                type="checkbox"
                                v-model="selectAll"
                                @change="selectAllUnpublished()"
                              />
                              <span
                                class="checkmark-2"
                                style="top: -2px"
                              ></span>

                              PRODUCT
                            </label>
                          </th>
                          <th
                            data-polaris-header-cell="true"
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                            scope="col"
                          >
                            MAIN TAG
                          </th>
                          <th
                            data-polaris-header-cell="true"
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                            v-if="categories"
                            scope="col"
                          >
                            CATEGORY
                          </th>
                          <th
                            data-polaris-header-cell="true"
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                            scope="col"
                          >
                            REFUND
                          </th>
                          <th
                            data-polaris-header-cell="true"
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                            scope="col"
                          >
                            STATUS
                          </th>
                          <th
                            data-polaris-header-cell="true"
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                            scope="col"
                          >
                            ACTION
                          </th>
                          <th
                            data-polaris-header-cell="true"
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--header Polaris-DataTable__Cell--numeric"
                            scope="col"
                          >
                            PREVIEW
                          </th>
                        </tr>
                        <tr class="tr-header" v-else>
                          <th colspan="6">
                            <div
                              class="buttons-container py-2"
                              v-if="isShopify && showProducts"
                            >
                              <label
                                class="custom-checkbox-2"
                                style="top: 12px; left: 18px; z-index: 20"
                              >
                                <input
                                  type="checkbox"
                                  v-model="selectAll"
                                  @change="unSelectAll()"
                                />
                                <span
                                  class="checkmark-2"
                                  style="top: -2px"
                                ></span>
                              </label>
                              <div style="display: flex">
                                <div
                                  class="Polaris-ButtonGroup Polaris-ButtonGroup--segmented"
                                  data-buttongroup-segmented="true"
                                >
                                  <div class="Polaris-ButtonGroup__Item">
                                    <button
                                      class="Polaris-Button Polaris-Button-selected"
                                      style="margin: 0; padding-left: 45px"
                                      type="button"
                                      :disabled="selected.length == 0"
                                    >
                                      <span class="Polaris-Button__Content"
                                        ><span class="Polaris-Button__Text"
                                          >{{ selected.length }} products
                                          selected</span
                                        ></span
                                      >
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </th>
                        </tr>
                        <tr class="bulk-container" v-if="selected.length > 0">
                          <th colspan="6">
                            <div
                              class="buttons-container"
                              v-if="isShopify && showProducts"
                            >
                              <div style="display: flex">
                                <div
                                  class="Polaris-ButtonGroup Polaris-ButtonGroup--segmented"
                                  data-buttongroup-segmented="true"
                                >
                                  <div class="Polaris-ButtonGroup__Item">
                                    <button
                                      class="Polaris-Button"
                                      style="margin: 0"
                                      type="button"
                                      @click="handleTagsBulkAssign"
                                    >
                                      <span class="Polaris-Button__Content">
                                        <span class="Polaris-Button__Text">
                                          Assign tags
                                        </span>
                                      </span>
                                    </button>
                                    <button
                                      class="Polaris-Button"
                                      style="margin: 0"
                                      type="button"
                                      @click="handleRefundBulkAssign"
                                    >
                                      <span class="Polaris-Button__Content">
                                        <span class="Polaris-Button__Text">
                                          Assign refund
                                        </span>
                                      </span>
                                    </button>
                                    <button
                                      class="Polaris-Button"
                                      style="margin: 0"
                                      type="button"
                                      @click="handleCategoryBulkAssign"
                                    >
                                      <span class="Polaris-Button__Content">
                                        <span class="Polaris-Button__Text">
                                          Assign category
                                        </span>
                                      </span>
                                    </button>
                                  </div>
                                  <div class="Polaris-ButtonGroup__Item">
                                    <button
                                      class="Polaris-Button Polaris-Button--primary"
                                      style="margin: 0"
                                      type="button"
                                      @click="publishProduct(selected)"
                                    >
                                      <span class="Polaris-Button__Content">
                                        <span class="Polaris-Button__Text">
                                          Publish Selected Items
                                        </span>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="Polaris-DataTable__TableRow Polaris-DataTable--hoverable each-row"
                          v-for="i in productsCopy"
                          :key="i.id"
                          :class="i.hasBeenPublished ? 'deactive' : ''"
                        >
                          <th
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--firstColumn"
                            scope="row"
                            style="display: flex; align-items: center"
                          >
                            <label class="custom-checkbox-2">
                              <input
                                type="checkbox"
                                :value="i.id"
                                v-model="selected"
                                @change="SelectedOne()"
                              />
                              <span
                                class="checkmark-2"
                                style="top: -9px !important"
                              ></span>
                            </label>

                            <span
                              class="shopify-table-title"
                              style="min-width: 240px; display: flex; gap: 5px"
                              v-if="i.productImages"
                            >
                              <div
                                class="shopify-image-wrapper"
                                v-if="i.productImages.length > 0"
                                :style="
                                  'background:url(' +
                                  `${i.productImages[0].thumbnailImage}` +
                                  ')'
                                "
                              ></div>
                              <div
                                v-else
                                class="shopify-image-wrapper"
                                :style="{
                                  'background-image':
                                    'url(' +
                                    require('@/assets/img/no-image.png') +
                                    ')',
                                }"
                                style="width: 20%"
                              ></div>
                              <div
                                class="d-flex align-center"
                                style="width: 80%"
                              >
                                {{
                                  i.title.length > 55
                                    ? i.title.slice(0, 55) + "..."
                                    : i.title
                                }}
                              </div>
                            </span>
                            <span
                              v-else
                              class="shopify-table-title"
                              style="min-width: 240px; display: flex; gap: 5px"
                            >
                              <div
                                class="shopify-image-wrapper"
                                :style="{
                                  'background-image':
                                    'url(' +
                                    require('@/assets/img/no-image.png') +
                                    ')',
                                }"
                              ></div>
                              <div
                                class="product-title d-flex align-center"
                                style="width: 80%"
                              >
                                {{
                                  i.title.length > 55
                                    ? i.title.slice(0, 55) + "..."
                                    : i.title
                                }}
                              </div>
                            </span>
                          </th>
                          <td
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric"
                          >
                            <SelectDropV2
                              v-if="i.productSellerTags"
                              :icon="'sort'"
                              :sortList="tags"
                              :defaultMultipleSelected="i.productSellerTags"
                              :placeholder="'Select tags'"
                              :maxWidth="'100%'"
                              :minWidth="'170px'"
                              :maxAvailableChoice="3"
                              :multiple="true"
                              :TextField="'value'"
                              :TextValue="'id'"
                              @multiselectedobject="
                                assignTag(
                                  $event.multiSelectedObject.map((a) => a.id),
                                  i.id
                                )
                              "
                            />
                            <SelectDropV2
                              v-else
                              :icon="'sort'"
                              :sortList="tags"
                              :maxWidth="'100%'"
                              :minWidth="'170px'"
                              :multiple="true"
                              :maxAvailableChoice="3"
                              :placeholder="'Select tags'"
                              :TextField="'value'"
                              :TextValue="'id'"
                              @multiselectedobject="
                                assignTag(
                                  $event.multiSelectedObject.map((a) => a.id),
                                  i.id
                                )
                              "
                            />
                          </td>
                          <td
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric"
                            v-if="categories"
                          >
                            <SelectDropV2
                              v-if="i.productCategories"
                              :icon="'sort'"
                              :sortList="categories"
                              :defaultSelected="i.productCategories[0].id"
                              :maxWidth="'100%'"
                              :placeholder="'No categories'"
                              :minWidth="'170px'"
                              :TextField="'title'"
                              :TextValue="'id'"
                              :Trim="20"
                              @sortbyobject="assignCategory($event, i.id)"
                            />
                            <SelectDropV2
                              v-else
                              :icon="'sort'"
                              :sortList="categories"
                              :maxWidth="'100%'"
                              :placeholder="'No categories'"
                              :minWidth="'170px'"
                              :TextField="'title'"
                              :TextValue="'id'"
                              @sortbyobject="assignCategory($event, i.id)"
                            />
                          </td>
                          <td
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric"
                          >
                            <SelectDropV2
                              :icon="'sort'"
                              :sortList="[
                                { id: 0, title: 'Not Refundable' },
                                { id: 7, title: 'Refundable - 7 Days' },
                                { id: 14, title: 'Refundable - 14 Days' },
                                { id: 30, title: 'Refundable - 30 Days' },
                                { id: 60, title: 'Refundable - 60 Days' },
                              ]"
                              :defaultSelected="i.refundDaysDuration"
                              :maxWidth="'100%'"
                              :minWidth="'200px'"
                              :placeholder="'Select Refund'"
                              :TextField="'title'"
                              :TextValue="'id'"
                              @sortbyobject="assignRefundDuration($event, i.id)"
                            />
                          </td>
                          <td
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric"
                          >
                            <div v-if="i.hasBeenPublished">
                              <span
                                class="Polaris-Badge Polaris-Badge--statusSuccess Polaris-Badge--progressComplete"
                                ><span class="Polaris-Badge__Pip"
                                  ><span class="Polaris-VisuallyHidden"
                                    >Status: Published. Your online store is
                                    visible.</span
                                  ></span
                                >Published</span
                              >
                              <div id="PolarisPortalsContainer"></div>
                            </div>

                            <div v-if="!i.hasBeenPublished">
                              <span
                                class="Polaris-Badge Polaris-Badge--progressComplete"
                                ><span class="Polaris-Badge__Pip"
                                  ><span class="Polaris-VisuallyHidden">
                                    Complete</span
                                  ></span
                                >Draft</span
                              >
                            </div>
                          </td>
                          <td
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric"
                          >
                            <div class="isPublishedButton">
                              <div>
                                <button
                                  class="Polaris-Button Polaris-Button--primary"
                                  type="button"
                                  v-if="!i.hasBeenPublished"
                                  @click="publishProduct([i.id])"
                                  :class="
                                    i.productCategories == null &&
                                    !i.refundDaysDuration
                                      ? 'deactive'
                                      : ''
                                  "
                                >
                                  <span class="Polaris-Button__Content"
                                    ><span class="Polaris-Button__Text"
                                      >Publish</span
                                    ></span
                                  >
                                </button>
                                <div id="PolarisPortalsContainer"></div>
                              </div>

                              <div>
                                <button
                                  class="Polaris-Button Polaris-Button--primary Polaris-Button--disabled"
                                  type="button"
                                  v-if="i.hasBeenPublished"
                                >
                                  <span class="Polaris-Button__Content"
                                    ><span class="Polaris-Button__Text"
                                      >Published</span
                                    ></span
                                  >
                                </button>
                                <div id="PolarisPortalsContainer"></div>
                              </div>
                            </div>
                          </td>
                          <td
                            class="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle Polaris-DataTable__Cell--numeric"
                          >
                            <div
                              v-if="i.hasBeenPublished"
                              class="isPublishedButton"
                              :class="i.hasBeenPublished ? 'clickable' : ''"
                            >
                              <a
                                @click="previewProduct(i.id)"
                                class="mx-5"
                                target="_blank"
                              >
                                <span
                                  class="Polaris-Button__Content Polaris-Button Polaris-Button--plain"
                                  >preview<span class="Polaris-Icon"
                                    ><svg
                                      viewBox="0 0 20 20"
                                      class="Polaris-Icon__Svg"
                                      focusable="false"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M11 4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V6.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L13.586 5H12a1 1 0 0 1-1-1zM3 6.5A1.5 1.5 0 0 1 4.5 5H8a1 1 0 0 1 0 2H5v8h8v-3a1 1 0 1 1 2 0v3.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 3 15.5v-9z"
                                      ></path></svg></span></span
                              ></a>
                            </div>
                            <div v-else>-</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- Pagination Section -->
              <div class="row" v-if="page_size > 1 && showProducts">
                <div class="col-sm-12 d-flex justify-center">
                  <v-pagination
                    v-model="page"
                    :length="page_size"
                    :total-visible="7"
                    @input="next"
                    color="#008f4a"
                    prev-icon="chevron_left"
                    next-icon="chevron_right"
                    class="assign-category-pagination"
                  ></v-pagination>
                </div>
              </div>
              <div v-if="!showProducts">
                <div class="container no-product-page">
                  <div class="row">
                    <div class="col-sm-5">
                      <h2 class="no-product-title">
                        List your products to start selling
                      </h2>
                      <p class="no-product-desc">
                        Expand your distribution channel and grow your business
                        by use the power of trusted online retailers around EU
                        and UK.
                      </p>
                      <!-- <span
                        v-if="!syncing"
                        class="text-warning mb-2 font-weight-bold"
                      >
                        We are syncing your products.please be Patient
                      </span>
                      <button
                        class="custom-button filled"
                        @click="startImportProducts"
                        v-if="!syncing"
                      >
                        Sync your product lists
                      </button> -->

                      <button class="custom-button deactive" v-if="!syncing">
                        Syncing ...
                      </button>
                      <span class="no-product-hint"
                        >Next step: assign categories & refund terms</span
                      >
                      <div class="no-product-wait" v-if="!syncing">
                        Syncing your product lists can takes 10 to 30 min. You
                        can leave this page and come back later.
                      </div>
                    </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-6">
                      <img
                        src="@/assets/img/empty-product-list.png"
                        alt="vgang"
                        class="no-product-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-main>

      <v-dialog v-model="tagsModal" max-width="500px">
        <div class="product-page-modal-header">Assign Tags</div>
        <div class="product-page-modal-body">
          <div class="mb-6">
            <SelectDropV2
              :icon="'sort'"
              :sortList="tags"
              :maxWidth="'360px'"
              :placeholder="'No tags'"
              :multiple="true"
              :minWidth="'170px'"
              :maxAvailableChoice="3"
              :TextField="'value'"
              :TextValue="'id'"
              @multiselectedobject="
                tag = $event.multiSelectedObject.map((a) => a.id)
              "
            />
          </div>
          <button
            class="vgang-button large"
            @click="assignBulkTag(selected, tag)"
          >
            Save Changes
          </button>
        </div>
      </v-dialog>
      <v-dialog v-model="refundModal" max-width="500px">
        <div class="product-page-modal-header">Assign Refund Terms</div>
        <div class="product-page-modal-body">
          <div class="mb-6">
            <SelectDropV2
              :icon="'sort'"
              :sortList="[
                { id: 0, title: 'Not Refundable' },
                { id: 7, title: 'Refundable - 7 Days' },
                { id: 14, title: 'Refundable - 14 Days' },
                { id: 30, title: 'Refundable - 30 Days' },
                { id: 60, title: 'Refundable - 60 Days' },
              ]"
              :maxWidth="'100%'"
              :minWidth="'200px'"
              :TextField="'title'"
              :TextValue="'id'"
              @sortbyobject="refund = $event.sort_by_object.id"
            />
          </div>
          <button
            class="vgang-button large"
            @click="assignBulkRefundDuration(selected, refund)"
          >
            Save Changes
          </button>
        </div>
      </v-dialog>
      <v-dialog v-model="categoryModal" max-width="500px">
        <div class="product-page-modal-header">Assign category</div>
        <div class="product-page-modal-body">
          <div class="mb-6">
            <SelectDropV2
              :icon="'sort'"
              :sortList="categories"
              :maxWidth="'360px'"
              :placeholder="'No categories'"
              :minWidth="'170px'"
              :TextField="'title'"
              :TextValue="'id'"
              @sortbyobject="category = $event.sort_by_object.id"
            />
          </div>
          <button
            class="vgang-button large"
            @click="assignBulkCategory(selected, category)"
          >
            Save Changes
          </button>
        </div>
      </v-dialog>
    </div>
  </main>
</template>
<script>
// Services
import SellerProducts from "@/services/SellerProducts";
import CommonData from "@/services/CommonData";
// Components
import CustomInput from "@/components/CustomInput.vue";

import HeaderNavigation from "../shopify/components/headerNavigation.vue";
import { getVariable } from "@/utils/conditionalStoreVariables";
import createApp from "@shopify/app-bridge";
import SelectDropV2 from "@/components/bases/SelectDropV2.vue";
import BaseSelectDropSort from "@/components/bases/BaseSelectDropSort.vue";
import { getSessionToken } from "@shopify/app-bridge-utils";
// import AuthService from "@/services/AuthenticationService";
export default {
  components: {
    CustomInput,
    HeaderNavigation,
    SelectDropV2,
    BaseSelectDropSort,
  },
  data() {
    return {
      shopifyToken: "",
      user: {},
      products: [],
      productsCopy: [],
      categories: [],
      category: null,
      tag: [],
      sortList: [
        { id: "Newest", value: "Newest" },
        { id: "Latest", value: "Latest" },
      ],
      filterOptions: [
        { label: "All", value: "all" },
        { label: "Live", value: "Live" },
        { label: "Published", value: "Published" },
        { label: "Unpublished", value: "Unpublished" },
        { label: "Out of Stock", value: "outOfStock" },
      ],
      selected: [],
      selectAll: false,
      refund: "0",
      staticImg: "https://picsum.photos/200",
      count: 24,
      page: 1,
      isActiveSearch: false,
      page_size: 0,
      unPublishedProductsPerPage: [],
      totalProductCount: "",
      tagsModal: false,
      categoryModal: false,
      refundModal: false,
      selectedNumbers: 0,
      showError: false,
      errorMessage: "",
      refundDaysDuration: 0,
      searchQuery: "",
      sortQuery: "Newest",
      SearchButtonIsDisabled: true,
      showFilters: false,
      userType: "supplier",
      isShopify: false,
      shopifyLoading: false,
      loading: false,
      showSuccess: false,
      showProducts: false,
      syncing: false,
      publicHost: "",
      sortBy: "newest",
      tags: null,
      selectedSort: null,
      componentKey: 0,
      selectedFilters: ["all"],
    };
  },

  async mounted() {
    if (
      process.env.VUE_APP_SOURCE_NAME == "shopifyimporter" &&
      window.self !== window.top
    ) {
      this.publicHost = process.env.VUE_APP_OAUTH_HOST;
      this.isShopify = true;
      this.user = getVariable("user");
    }
    try {
      await this.getCategories();
      await this.getTags();
      await this.getProducts();
      this.shopifyToken = await getVariable("shopifyToken");
    } catch (error) {
      console.log("inventory Management error:", error);
    }
  },
  methods: {
    SortBy(event) {
      this.selectedSort = event;
      let pag = {
        count: this.count,
        offset: 0,
        sort: event.value,
      };
      SellerProducts.getProducts(pag)
        .then((response) => {
          this.totalProductCount = response.data.totalCount;
          this.productsCopy = response.data.products;
          this.unPublishedProductsPerPage = this.productsCopy.filter(
            (item) => item.hasBeenPublished == false
          );
          this.page_size = Math.ceil(response.data.totalCount / this.count);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    forceRerender() {
      this.componentKey += 1;
    },
    async initiateAppBridge() {
      const apiKey = process.env.VUE_APP_SHOPIFY_API_KEY;
      const host = await getVariable("host");
      const app = createApp({
        apiKey: apiKey,
        host: host,
      });

      try {
        const response = await getSessionToken(app);
        return response;
      } catch (error) {
        console.log(error);
      }
    },
    async previewProduct(id) {
      const accessToken = await this.initiateAppBridge();
      const pathToPublicSingleProduct = `${this.publicHost}/single-product/${id}?accessToken=${accessToken}&source=importer_services&sourceName=shopifyimporter`;
      window.open(pathToPublicSingleProduct);
    },
    startImportProducts() {
      SellerProducts.startImportProducts();
      this.syncing = true;
    },
    async removeSearch() {
      this.isActiveSearch = !this.isActiveSearch;
      this.searchQuery = "";
    },
    search() {
      let pag = {
        count: this.count,
        offset: 0,
        search: this.searchQuery,
      };
      SellerProducts.getProducts(pag)
        .then((response) => {
          this.totalProductCount = response.data.totalCount;
          this.productsCopy = response.data.products;
          this.unPublishedProductsPerPage = this.productsCopy.filter(
            (item) => item.hasBeenPublished == false
          );
          this.page_size = Math.ceil(response.data.totalCount / this.count);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleFilter(filter) {
      let pag = {
        count: this.count,
        offset: 0,
        filter: filter,
      };
      SellerProducts.getProducts(pag)
        .then((response) => {
          this.totalProductCount = response.data.totalCount;
          this.products = response.data.products;
          this.unPublishedProductsPerPage = this.products.filter(
            (item) => item.hasBeenPublished == false
          );
          this.page_size = Math.ceil(response.data.totalCount / this.count);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    next(page) {
      // console.log(page);
      this.getProducts();
      this.unSelectAll();
    },
    refreshGetProduct() {
      if (this.syncing) {
        setTimeout(() => {
          this.getProducts();
        }, 5000);
      }
    },
    toggleFilter(filter) {
      if (this.selectedFilters.includes(filter)) {
        // If the filter is already selected, deselect it
        this.selectedFilters = ["all"];
      } else {
        // Deselect all filters and select the clicked filter
        this.selectedFilters = [filter];
      }
    },
    isSelectedFilter(filter) {
      return this.selectedFilters.includes(filter);
    },
    async getProducts() {
      let pag = {
        count: this.count,
        offset: this.count * (this.page - 1),
      };
      try {
        const res = await SellerProducts.getProducts(pag);
        if (res.data.startedImporting && !res.data.finishedImporting) {
          this.syncing = true;
          this.refreshGetProduct();
        } else {
          this.syncing = false;
        }
        this.showProducts = res.data.finishedImporting;
        this.totalProductCount = res.data.totalCount;
        this.products = res.data.products;
        this.productsCopy = res.data.products;
        this.unPublishedProductsPerPage = this.products.filter(
          (item) => item.hasBeenPublished == false
        );
        this.page_size = Math.ceil(res.data.totalCount / this.count);
        this.shopifyLoading = false;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getCategories() {
      try {
        const res = await CommonData.getCategories();
        if (res) this.categories = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getTags() {
      try {
        const res = await CommonData.getTags();
        console.log("res.data.values :>> ", res.data.values);
        if (res) this.tags = res.data.values;
      } catch (error) {
        console.log(error);
      }
    },
    async assignTag(e, id) {
      let data = {
        sellerTagValueIds: e,
        productIds: id.length > 1 ? id : [id],
      };
      SellerProducts.assignTag(data)
        .then(async (response) => {
          // console.log(response);
          await this.getProducts();
          // this.forceRerender();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async assignBulkTag(selected, tagId) {
      let data = {
        sellerTagValueIds: tagId,
        productIds: selected,
      };
      try {
        const res = await SellerProducts.assignTag(data);
        if (res) {
          await this.getProducts();
          await this.handleTagsBulkAssign();
          this.forceRerender();
        }
      } catch (error) {
        console.log(error);
        this.handleTagsBulkAssign();
      }
    },
    assignCategory(e, id) {
      let data = {
        categoryId: parseInt(e.sort_by_object.id),
        productIds: id.length > 1 ? id : [id],
      };
      SellerProducts.assignCategory(data)
        .then(async (response) => {
          // console.log(response);
          await this.getProducts();
          this.forceRerender();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async assignBulkCategory(selected, categoryId) {
      let data = {
        categoryId: categoryId,
        productIds: selected,
      };
      try {
        const res = await SellerProducts.assignCategory(data);
        if (res) {
          await this.getProducts();
          await this.handleCategoryBulkAssign();
          this.forceRerender();
        }
      } catch (error) {
        this.handleCategoryBulkAssign();
        console.log(error);
      }
    },
    assignRefundDuration(e, id) {
      let data = {
        refundDaysDuration: parseInt(e.sort_by_object.id),
        productIds: id.length > 1 ? id : [id],
      };
      SellerProducts.assignRefundDuration(data)
        .then(async (response) => {
          // console.log(response);
          await this.getProducts();
          this.forceRerender();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    assignBulkRefundDuration(selected, refundDaysDuration) {
      let data = {
        refundDaysDuration: parseInt(refundDaysDuration),
        productIds: selected,
      };
      SellerProducts.assignRefundDuration(data)
        .then(async (response) => {
          // console.log(response);
          await this.getProducts();
          await this.handleRefundBulkAssign();
          this.forceRerender();
        })
        .catch((e) => {
          this.handleRefundBulkAssign();
          console.log(e);
        });
    },
    publishProduct(id) {
      let data = {
        productIds: id,
      };
      SellerProducts.publishProduct(data)
        .then(async (response) => {
          // console.log(response);
          await this.getProducts();
          this.showError = false;
          this.showSuccess = true;
          this.selectAll = false;
          this.selected = [];
          this.forceRerender();
        })
        .catch((e) => {
          this.showError = true;
          this.showSuccess = false;
          this.errorMessage = e.response.data.message;
          console.log(e);
        });
    },
    Selected() {
      const list = this.products.map((product) => {
        if (!product.hasBeenPublished) {
          return product.id;
        }
      });

      if (list.length === this.select.length) {
        this.selectAll = true;
        this.select;
      }
    },
    SelectedOne() {
      if (!this.selected.length) this.unSelectAll();
      else {
        const list = this.products.filter(
          (product) => !product.hasBeenPublished
        );

        if (list.length == this.selected.length) this.selectAllUnpublished();
        else this.selectAll = false;
      }
    },
    select() {
      this.selected = [];

      if (this.selectAll) {
        this.products.forEach((prod) => {
          if (!prod.hasBeenPublished) this.selected.push(prod.id);
        });
      }
      this.selectedNumbers = this.selected.length;
    },
    selectAllUnpublished() {
      this.selected = [];
      this.products.forEach((prod) => {
        if (!prod.hasBeenPublished) this.selected.push(prod.id);
      });
      this.selectedNumbers = this.selected.length;
      this.selectAll = true;
    },
    unSelectAll() {
      this.selected = [];
      this.selectAll = false;
    },
    handleCategoryBulkAssign() {
      this.categoryModal = !this.categoryModal;
    },
    handleTagsBulkAssign() {
      this.tagsModal = !this.tagsModal;
    },
    handleRefundBulkAssign() {
      this.refundModal = !this.refundModal;
    },
    checkSearchButtonStatus() {
      if (this.searchQuery.length > 0) {
        this.SearchButtonIsDisabled = false;
      } else {
        this.SearchButtonIsDisabled = true;
      }
    },
  },
  watch: {
    searchQuery: function (val, oldVal) {
      if (oldVal.length && val.length === 0) {
        this.search();
      }
    },
    selectedFilters: function (val, oldVal) {
      if (val == "all") {
        this.productsCopy = JSON.parse(JSON.stringify(this.products));
      }

      if (val == "Published") {
        let filteredProduct = this.products.filter(
          (item) => item.hasBeenPublished == true
        );
        this.productsCopy = JSON.parse(JSON.stringify(filteredProduct));
      }
      if (val == "Unpublished") {
        let filteredProduct = this.products.filter(
          (item) => item.hasBeenPublished == false
        );
        this.productsCopy = JSON.parse(JSON.stringify(filteredProduct));
      }
    },
  },
};
</script>
<style lang="scss">
.assign-category-v2 {
  .Search-your-products-btn {
    .Polaris-Button__Content {
      @include sm {
        font-size: 12px !important;
      }
    }
  }

  .Polaris-TextField__Input {
    @include sm {
      font-size: 14px;
    }
  }
  .buttons-container-sort {
    position: absolute;
    right: 1%;
    @include lg {
      position: relative;
      right: unset;
    }
  }
  .Polaris-DataTable__TableRow {
    border-bottom: 1px solid #e1e3e5;
  }
  .baseSelect__options__option {
    font-size: 12px;
  }
  .product-title {
    color: #202223;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-left: 8px;
  }
  .tr-header {
    border: 1px solid #e1e3e5;
    border-right: unset;
    border-left: unset;
    background: #fafbfb;
  }
  .tablefilters {
    display: flex;
    flex: 1;

    @include lg {
    }

    @include sm {
      flex-wrap: wrap;
    }
  }
  .filter-option {
    padding: 5px 10px;
    border-radius: 3px;
    margin-right: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  .tablefilters .active {
    background: #b1f7d8;
  }
  .bulk-container {
    position: fixed;
    bottom: 13%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000000000;
    background: #202123;
    padding: 18px;
    border-radius: 10px;
    @include lg {
      left: 50%;
      transform: translateX(-75%);
      .Polaris-ButtonGroup--segmented {
        display: grid;
        gap: 12px;
      }
    }
    .Polaris-Button {
      background: #202123;
      color: #e4e5e7;
      border: 1px solid #e4e5e7;
      border-radius: 5px;
    }

    .Polaris-ButtonGroup__Item {
      display: flex;
      gap: 8px;
      margin-left: 8px;
    }
  }

  .Polaris-Button-selected {
    border: unset;
    box-shadow: unset;
    background: unset;
    padding-left: 20px;
    &:hover {
      background: unset;
    }
  }
  tbody {
    border-top: 1px solid #e1e3e5;
  }
  .Polaris-DataTable__Cell--header {
    border-bottom: unset;
    color: #6d7175;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }
  .Sort-type {
    width: auto;
    &:hover {
      .baseSelect__label {
        background: rgb(250, 248, 248);
      }
    }
    .baseSelect__label__input {
      display: none;
    }
    .baseSelect__label {
      padding: 8px;
    }
    .baseSelect__options {
      right: 0;
      width: 100px;
    }
  }

  .shopify-table-title {
    padding-left: 10px;
  }
  .shopify-image-wrapper {
    width: 60px;
    height: 60px;
    background-position: center center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    border: 1px solid #ededed;
    display: inline-block;
    vertical-align: middle;
  }
  .assign-search-container {
    width: 500px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    @include sm {
      width: 284px;
    }
    .cancel-button {
      font-size: 14px;
      cursor: pointer;
      border-radius: 14px;
      padding: 5px 8px;
      transition: all 0.2s ease-in-out;
      &:hover {
        background: #ededed;
      }
    }
  }
  .Polaris-DataTable__Cell--numeric {
    text-align: left !important;
  }
  .Polaris-Select--disabled {
    pointer-events: none !important;
  }

  .Polaris-Button {
    &.deactive {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  .no-product-page {
    padding-top: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    .no-product-hint {
      display: block;
      margin-top: 15px;
    }
    .no-product-image {
      margin-top: -80px;
    }
    .no-product-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #0b0b0b;
      margin-bottom: 32px;
    }
    .no-product-desc {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      /* or 171% */

      display: flex;
      align-items: center;

      /* Grey - 600 */

      color: #435160;
    }
    .no-product-wait {
      margin-top: 15px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      /* Grey - 600 */

      color: #435160;
      background: #e7f3fa;
      border-radius: 10px;
      padding: 20px;
    }
  }
  .np-footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #f6f6f7;
    padding: 20px;
    margin: 180px 0 0 0 !important;
    flex-wrap: wrap;
    position: absolute !important;
    width: 100% !important;
    bottom: 0 !important;

    .noFee {
      color: #008060;
    }
    ._link {
      margin-left: 32px;
      margin-right: 32px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      color: #435160;
    }
  }
  .clickable {
    pointer-events: all;
  }
  .assign-category-header {
    gap: 12px;
    padding: 12px;
    justify-content: flex-end;
  }

  .Polaris-Button-search {
    padding: 8px 5px;
  }
  .Polaris-Button:focus::after {
    outline: none;
    background: unset;
    box-shadow: none;
    border: unset;
  }
  .Polaris-Button svg {
    fill: #3b3e41;
  }
  .assign-category-table {
    overflow-x: scroll;
    padding: 0 0 24px 0;
  }
  .Polaris-Select__Input {
    &:hover {
      cursor: pointer;
    }
  }
  .input_filter {
    width: 100% !important;
  }
  .shopify-image-wrapper {
    width: 45px;
    height: 45px;
  }
  @media screen and (max-width: 720px) {
  }
  .assign-category-header {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .buttons-container {
    @include lg {
      width: 100% !important;
    }
    @include sm {
      width: 380px !important;
    }
  }
  .clickable {
    pointer-events: all;
  }
}
.product-page-modal-header {
  border-bottom: 1px solid lightgray;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}
.product-page-modal-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.product-page-modal-label {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #435160;
}
</style>

<template>
  <ul role="tablist" class="Polaris-Tabs">
    <li
      class="polaris-menu"
      @click="$router.push({ path: '/supplier/assign-categoryV2' })"
      :class="{
        active: $route.path == '/supplier/assign-categoryV2',
      }"
    >
      Inventory Management
    </li>
    <li
      class="polaris-menu"
      @click="$router.push({ path: '/supplier/order-list' })"
      :class="{
        active: $route.path == '/supplier/order-list',
      }"
    >
      Order Management
    </li>
    <li
      class="polaris-menu"
      @click="$router.push({ path: '/supplier/shipping' })"
      :class="{
        active: $route.path == '/supplier/shipping',
      }"
    >
      Shipping
    </li>
    <li
      class="polaris-menu"
      @click="$router.push({ path: '/supplier/settings' })"
      :class="{
        active: $route.path == '/supplier/settings',
      }"
    >
      Settings
    </li>
  </ul>
</template>

<script>
export default {};
</script>

<style lang="scss">
.polaris-menu {
  padding-top: 35px;
  padding-bottom: 12px;
  margin-right: 35px;
  border-radius: 2px;
  cursor: pointer;
  &.active {
    border-bottom: 4px solid #008060;
  }
}
</style>

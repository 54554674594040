import api from "@/services/Api";
import authHeader from "@/services/AuthHeader";

export default {
  getProducts(pagination) {
    return api.get(
       "sellers/products",
      { params: pagination },
      { headers: authHeader() }
    );
  },
  getSellers(sellerID) {
    return api.get(
      "retailers/sellers/"+sellerID,
      {},
      { headers: authHeader() }
    );
  },
  assignCategory(data) {
    return api.post("sellers/assign-categories", data, {
      headers: authHeader(),
    });
  },
  assignTag(data) {
    return api.post("sellers/assign-tags", data, {
      headers: authHeader(),
    });
  },
  assignRefundDuration(data) {
    return api.post("sellers/assign-refund-duration", data, {
      headers: authHeader(),
    });
  },
  publishProduct(data) {
    return api.post("sellers/publish-products", data, {
      headers: authHeader(),
    });
  },
  /**
   * please check the url of this api and make sure it is correct ---bahman---
   */
  startImportProducts() {
    return api.post(
      "sellers/products/import",
      {},
      {
        headers: authHeader(),
      }
    );
  },
};
